.logout-bar{
  float: right;
  padding-right: 20px;
  text-align: right;
  cursor: pointer;
}

:root .logout-bar.ant-dropdown-trigger .anticon:not(.anticon-ellipsis), :root .ant-dropdown-link .anticon:not(.anticon-ellipsis) {
  font-size: 18px;
}

.ant-layout-header {
  background-color: #fff;
  border-bottom: 2px solid #e8e8e8;
  padding: 0px 24px;

  .logo {
    width: 120px;
    height: 31px;
    background-image: url("/static/logo.png");
    margin: 16px 24px 16px 0;
    float: left;
    background-size: 120px;
  }

}

.navbar-username {
  padding: 5px 12px;
  margin: 0;
  clear: both;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  transition: all .3s;
  line-height: 22px;
}

.fixed-header {
  position: fixed;
  top: 0;
  z-index: 10; // to avoid superposition with other higher z-index's elements 
  width: 100%;
}

.icons-position {
  text-align: right;
  padding-right: 24px;
}


@media screen and (max-width:768px) {
  .icons-on-mobile {
    margin-top: 12px;
  }

  .icons-position {
    // text-align: right;
    // padding-right: 24px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width:768px) {
  .icons-margin {
    margin-right: 15px;
  }
}

