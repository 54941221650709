@import "~antd/dist/antd.less";   // import official less entry file
@import "colors.less";
@import "header.less";
@import "login.less";
@import "orders.less";
@import "product.less";
@import "deals.less";
@import "catalog.less";
@import "appointments.less";
@import "adminPanel.less";
@import "productModal.less";

@icon-url: '../public/static/fonts/iconfont';

html {
  height: 100%;
  min-height: 100%;
}
body {
  height:100%;
  min-height: 100%;
}

#__next {
  height:100%;
  min-height: 100%;

  .ant-layout {
    height:100%;
    min-height: 100%;
    //background-image: url("/static/login-bkg.jpg");
  }
}

.has-error{
  color: red;
}

.error-container {
  margin-top: -50px;
  background-color: '#ececec';
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner-wrapper {
    margin: 0px auto;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.banner-thumbnail{
  width: 100%;
  max-height: 100%;
  height: auto;
}

.images-upload-container {
  display: flex;
  flex-direction: column
}

.upload-button {
  align-self: flex-end;
  margin-bottom: 1rem;
}

.upload-button .cancel-button{
  margin-right: 1em;
}

.uploader-image-container {
  margin-top: 1rem;
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 1rem;
  display: inline-block;
}

.clearfix {
  display: inline;
}

.ant-upload-list-picture-card .ant-upload-list-item{
  width: 180px;
  height: 180px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 180px;
  height: 180px;
}

.uploader-image-container .image-title {
  font-weight: 800;
  font-size: 1rem;
}

.upload-button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.upload-button-container .cancel-button {
  margin-right: 1rem
}
.anticon {
  color: @red;
}

.ant-layout-header{
  min-height: 64px;
  height: auto;
}

.layout .ant-layout-content {
  min-height: initial;
  padding: 0px 0px;
  background: white;
}

@media (max-width: @screen-md-max) {
  .ant-layout-content {
    padding: 0px;
  }

  .flexOnMobile {
    margin-right: 20px;
    display: flex;
  }
}

@media (max-width: 767px){
  .ant-modal {
    max-width: calc(95vw);
    margin: auto;
  }
}

@media (min-width: 768px) {
  .banner-image {
    height: 66px;
    width: 601px;
  }
}

.navbar-username .ant-dropdown-menu-item, .navbar-username .ant-dropdown-menu-item-group-list {
  list-style: none;
  padding: 0px;
}

.navbar-username .ant-dropdown-menu-item-group-title {
  text-align: left;
  padding: 0px;
}

.ant-menu-submenu {
  background-color: white;
}

.ant-menu-submenu > .ant-menu {
  background-color: white;
}

.ant-dropdown-menu-item > a {
  padding: inherit;
  margin: inherit;
}

.ant-menu-item a:hover, .ant-dropdown-menu-item a:hover {
  text-decoration: none;
  color: inherit;
}

.full-width {
  width: 100%;
}

.ant-layout-footer {
  a:hover, a:focus {
    text-decoration: none;
    color: @secondary-color;
  }

  a {
    color: @primary-color;
  }
}

.ant-modal-close:focus {
  outline: none;
}

.administrator {
  padding: 0px 8px;
}

tfoot > tr > td {
  //matches .ant-table-tbody tr td
  padding: 16px 0;
}

.cart-item-table tr > th {
  padding: 8px 5px !important;
}

.cart-item-table tr > td {
  word-break: break-all;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.std-lateral-padding {
  padding-left: 5px;
  padding-right: 5px;
}

.std-form-padding {
  .ant-form-item {
    .std-lateral-padding();
  }
}

.statistics-div-column{
  text-align: right !important;
}

.statistics-div-table{
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, tfoot > tr > td {
    padding: 10px 10px;
    white-space: nowrap;
  }
}

.highlighter {
  background-color: #FFFF00
}

.ant-table-body {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .ant-carousel .slick-dots {
    position: relative;
  }
}

.ant-carousel .slick-dots {
  height: 8px;
  overflow: hidden;
}

.ant-carousel .slick-dots li button {
  height: 8px;
  width: 20px;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 30px;
}

.deleted-row {
  background-color: #dcdcdc;
  //pointer-events: none;
}

.ant-upload-list-picture-card-container {
  width: auto;
  height: auto;
}

.favorite-left-panel {
  cursor: pointer;
  margin-left: 40px;
  margin-top: 5px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);

  p:hover {
    font-weight: bold;
  }
}

.disabled-row {
  background-color: #dcdcdc;
}

.no-product-in-favorite {
  background-color: #eee;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
}

.ant-select-combobox .ant-select-search__field,
.ant-select-selection__clear,
.ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component {
  z-index: auto;
}

@media (min-width: 768px) {
  .right-align-items {
    display: flex;
    justify-content: flex-end;
  }
}