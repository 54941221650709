.product-container {
  margin-top: 3rem;
}

.product-item-container {
    padding-bottom: 1rem;
  }

  .product-item-container .product-property{
    font-weight: 800
  }

  .product-icon{
    width: 20px;
    height: 20px;
  }

  .element-grid-container{
    display:flex;
    overflow:auto;
    height: 25rem;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .image-product-container {
    border: 1px solid lightgrey;
    border-radius: .3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: .3rem;
    width: 10rem;
    height: 10rem;
  }

  .image-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

.image-selected{
  border: 2px solid #1890ff;
}
  .image-product-container .image-product-checkbox{
    padding-bottom: 0.5rem;

  }
  .image-product-container img{
      width: 100%;
  }
  .image-product-container img:hover{
    opacity: 0.5;
    cursor: pointer;
  }

.main-picture-container{
  display:flex;
  flex-direction:column;
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  height:100%;
  padding-left: 1rem;
  margin-top: 2rem;
  width: 350px;
}

.main-picture-content img{
  width:100%;
  align-self: center;
  padding: .5rem;
}

@media screen and (min-width:768px) {
  .main-picture-content img{
    width: 10rem;
    align-self: center;
    width: 100%;
  }
}

.main-picture-button{
  align-self: flex-end;
  margin-right: 1rem;
  margin-top: 1rem
}
.main-picture-content{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 180px;
  height: 180px;
}

.main-picture-header{
  display:flex;
  flex-direction: column;
}

.main-picture-header-container{
  display: flex;
  align-items: center;
}

.main-picture-header-container .main-picture-label{
  font-weight: 800;
  flex: 1 0;
  align-self: center;
  font-size: 1rem;
  margin-top: 1rem;
}

.main-picture-header-container .main-picture-button {
  align-self: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.ant-upload-list-picture-card-container {
  width: auto;
  height: auto;
}

.productLabelContainer {
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-height: 88px;
  min-width: 88px;
  align-content: center;
}

.productLabelContainer:hover {
  cursor: pointer;
}

.textLabelContent {
  position: absolute;
  top: 45%;
  left: -5%;
  right: -5%;
  background-color: orange;
  color: white;
  padding: 2px 0;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  white-space: nowrap;
  width: 90px;
}

.ant-image-preview-operations-wrapper .anticon {
  color: @color-4;
}