
.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no-header-table .ant-table-thead {
    display: none;
}