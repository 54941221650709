@dark-grey: #bbbbbb;
@light-grey: #e8e8e8;
@red: #d20404;
@blue: #00a0c6;
@white: #ffffff;

@newDarkGrey: #7d7d7d;

// Red gradient
@redGradientLight: #fe4e37;
@redGradientLightTransparent: rgba(254, 78, 55, 0.3);
@redGradient: #ea0100;

// Yellow gradient
@yellowGradientLight: #f0e35b;
@yellowGradient: #fdc652;

// Blue gradient
@blueGradientLight: #77c0e2;
@blueGradient: #4483b3;

@color-1: #f0dcd8;
@color-2: rgb(128, 212, 233);
@color-3: #d6857c;
@color-4: #c95c55;
@color-5: #bd3631;
@color-6: #b11212;
@color-7: #8a070b;
@color-8: #630007;
@color-9: #3d0006;
@color-10: #170003;
@menu-item-color: rgba(0, 0, 0, 0.65);

@primary-color: @red;
@secondary-color: @color-8;
@link-color: @primary-color;

@event-created: #ffffff;
@event-rescheduled: #eeb211;
@event-cancelled: #666666;
@event-confirmed: #009925;
@event-concluded: #3369e8;
@event-future: #d50f25;

@login-grey: #f6f6f6;
@login-black: #1d1d1d;

// .red-gradient {
//   background-image: linear-gradient(to right, @redGradientLight, @redGradient);
// }

// these are the same that redGradient colors but with a little transparency
.red-gradient {
  background-image: linear-gradient(to right,
    rgba(254, 78, 55, 0.9),
    rgba(234, 1, 0, 0.9));
}

.yellow-gradient {
  background-image: linear-gradient(to right, @yellowGradient, @yellowGradientLight);
}

.blue-gradient {
  background-image: linear-gradient(to right, @blueGradient, @blueGradientLight);
}
