.hover_group:hover {
  opacity: 1;
  border: 1px solid red;
}

.hover_group {
  border: solid 2px red;
}

#projectsvg {
  position: relative;
  width: 100%;
  padding-bottom: 77%;
  vertical-align: middle;
  margin: 0;
  overflow: hidden;
}
#projectsvg svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid black;
  cursor: crosshair;
}

.svgDeals {
  cursor: crosshair;
  pointer-events: all;
}

.rect_products:hover {
  stroke: red;
}

.uploader-image-container-deal {
  margin-top: 1rem;
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
}

.uploader-image-container-deal .image-title {
  font-weight: 800;
  font-size: 1rem;
}

.dealsCRUDProductDetail {
  label {
    padding-right: 5px;
    font-weight: bold;
  }
}

.deal-item-card-picture {
  width: 80%;
  margin: 0px auto;
  cursor: pointer;
  img {
    width: 100%;
    padding: 2px;
  }
}

#dealDetails {
  pointer-events: all;
}

.rect_products_view {
  &:hover {
    fill: black;
    opacity: 0.3;
    cursor: pointer;
    pointer-events: all;
  }
}

.rect_products_selected {
  fill: black;
  opacity: 0.5;
  cursor: pointer;
  pointer-events: all;
}

.items-container {
  flex-grow: 1;
}

.row-product-selected {
  background-color: #f0dcd8;
}

.catalog-deal-bar {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  padding-bottom: 10px;

  i {
    color: @red;
  }

  .actions {
    display: inline-block;
    font-size: 22px;
    text-align: center;
    cursor: pointer;
  }
}

.deal-products-table {
  width: 600px;

  .ant-table-tbody > tr:hover {
    cursor: pointer;
  }

  .ant-modal-body {
    padding: 25px 25px;

    .ant-modal-confirm-body {
      .ant-modal-confirm-content {
        margin-left: 0px;
      }
    }
  }
}
