.login-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: @login-grey;

  .login-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: @login-grey;

    .centered-form-and-image {
      max-width: 637px;
      width: 100%;
      margin-right: 5%;

      .form-and-image-container {
        display: flex;
        position: relative;
        width: 100%;
        max-width: 637px;
        justify-content: flex-end;

        .computer-image-container {
          position: absolute;
          left: 0;
          top: 60%;
          transform: translateY(-50%);
        }

        .computer-image {
          max-height: 250px;
        }

        .login-form {
          height: 455px;
          background-color: @white;
          width: 100%;
          max-width: 425px;
          border-radius: 1rem;
          box-shadow: 0 0 20px @dark-grey, 0 0 10px @dark-grey;

          .form-header {
            text-align: center;

            .login-logo-container {
              padding-top: 10px;
              padding-bottom: 10px;
            }

            .login-title,
            .login-subtitle {
              text-align: center;
              text-transform: uppercase;
              font-size: 15pt;
              font-weight: bold;
              height: 25px;
              line-height: 25px;
            }

            .login-title {
              font-weight: 600;
            }

            .login-subtitle {
              color: #d10000;
            }
          }

          .login-form-content {
            padding-top: 30px;
            padding-left: 30%;
            padding-right: 10%;

            .login-input-label {
              color: @newDarkGrey;

              .input-label-bold {
                font-weight: bold;
                text-transform: lowercase;
              }
            }

            .login-form-button {
              width: 50%;
              background-image: linear-gradient(to right,
                  @redGradientLight,
                  @redGradient );
              color: #fff;
              border-radius: 7px;
              font-family: 'Roboto', sans-serif;
              text-transform: uppercase;
              font-weight: 600;
              padding: 0px 0px;
              margin-bottom: 0rem;

            }

            .login-form-action {
              color: @newDarkGrey;
              font-weight: 700;
              font-size: 10pt;
              text-decoration: none;
            }

            .form-login-footer {
              padding-top: 10px;

            .recovery-note {
              font-weight: 500;
              color: #d10000;
            }
            }
          }
        }
      }
    }
  }

  .login-footer {
    height: 15vh;
    background-color: @login-black;
    color: @dark-grey;
    font-size: 9pt;

    .footer-branch-container {
      padding: 10px 20% 20px 20%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 80%;

      a {
        color: @dark-grey;
      }

      .branch-address-title {
        font-weight: bold;
        color: #fff;
      }

      .footer-divider {
        background-color: @dark-grey;
        height: 100%;
      }
    }

    .form-login-address {
      text-align: center;
    }

    .form-login-url {
      text-align: center;
      border-radius: 5px;

      a {
        color: @dark-grey;
      }
    }
  }
}

@media (max-width: 680px) {
  .login-layout .login-container {
    align-items: unset;
    margin-top: 10%;

    .centered-form-and-image {
      // margin-right: 0%;
      margin: 0 auto;
      max-width: 350px;

      .form-and-image-container {
        flex-direction: column-reverse;
        align-items: center;

        .computer-image-container {
          top: 90%;
          bottom: 50%;
          left: 50%;
          transform: translateX(-50%);
        }

        .login-form {
          .login-form-content {
            padding-top: 30px;
            padding-left: 20%;
            padding-right: 20%;

            .ant-form {
              text-align: center;
            }

            .login-input-label {
              display: block;
              text-align: center;
            }

            .ant-form-item {
              margin-bottom: 12px;
            }

            .login-form-button {
              width: 80%;
            }
          }
        }
      }
    }
    .recovery {
      margin-left: unset;
    }
  }

  .login-layout .login-footer {
    .footer-branch-container {
      padding: 15px 10% 10px 10%;
      margin: auto 0;
      flex-direction: column;

      .footer-divider {
        display: none;
      }

      .form-login-address {
        padding: 5px 0;

        br,
        .separator,
        .branch-address,
        a[href^='mailto:'],
        span+br+span {
          display: none;
        }
      }
    }

    .form-login-url {
      display: none;
    }
  }

}

.recovery {
  margin-left: 50%;
}

.password-recovery {
  margin-top: 15x;

  .ant-form-item {
    margin-bottom: 12px;
  }
}