// ItemStyles.less
.item-wrapper {
  width: 140px;
  margin: 5px 26px;
  cursor: pointer;
}

.item {
  height: 100px;
  background-color: #ff3399;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-image .icon-wrapper {
  display: flex;
  font-size: 35px;

  .anticon {
    color: #fff;
  }
}

.item-label {
  margin-top: 6px;
  text-align: center;
}
