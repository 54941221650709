.fc-button-primary {
    border-color: @color-6 !important;
    background-color: @color-6 !important;

    &:hover {
        border-color: @color-5 !important;
        background-color: @color-5 !important;
    }

    &:active, .active, .fc-button-active {
        border-color: @color-7 !important;
        background-color: @color-7 !important;
        box-shadow: none !important;
    }

    &:focus {
        box-shadow: none !important;
    }

    &:disabled {
        border-color: @color-4 !important;
        background-color: @color-4 !important;
        box-shadow: none !important;
    }
}

.calendar-event-created {
    color: #333333 !important;
    border-color: #333333 !important;
    background-color: @event-created !important;

    .fc-event-main {
        color: #333333 !important;
    }
}

.calendar-event-rescheduled {
    color: #ffffff !important;
    border-color: @event-rescheduled !important;
    background-color: @event-rescheduled !important;
}

.calendar-event-cancelled {
    color: #ffffff !important;
    border-color: @event-cancelled !important;
    background-color: @event-cancelled !important;
}

.calendar-event-confirmed {
    color: #ffffff !important;
    border-color: @event-confirmed !important;
    background-color: @event-confirmed !important;
}

.calendar-event-concluded {
    color: #ffffff !important;
    border-color: @event-concluded !important;
    background-color: @event-concluded !important;
}

.calendar-event-future {
    color: #ffffff !important;
    border-color: @event-future !important;
    background-color: @event-future !important;
}

.calendar-event-modal {
    .ant-modal {
        width: 700px !important;
    }
}

.calendar-export-modal {
    .ant-modal {
        width: 400px !important;
    }
}

.auto-complete {
    min-width: 300px;
}
