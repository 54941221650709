.catalog-item-detail-modal {
  font-family: 'Roboto', sans-serif;

  .ant-modal {
    width: 620px !important;
  }

  .ant-modal-body {
    padding: 0px !important;
  }

  .ant-modal-content {
    border-radius: 10px;
  }
  
  .catalog-item-detail {
    /*.flex-column();*/
    display: flex;
    flex-direction: column;
    // box-shadow: 0 0 20px @dark-grey, 0 0 10px @dark-grey;

    .catalog-item-detail-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px 10px 0 0;
      font-weight: bold;
      height: 60px;

      .title {
        color: white;
        margin-left: 15px;
        font-size: 20px;
      }

      .icons-container {
        padding-right: 25px;
        margin-left: auto;
        display: flex;

        .icon-card-item {
          margin-top: 1px;
          font-size: 25px;
          color: white;
          line-height: 40px;
          padding-left: 10px;
        }
      }
    }

    .product-modal-description-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-header-model-product {
        width: 80%;
        color: @newDarkGrey;
        font-weight: 600;
        font-size: 15px;
        padding: 0 15px 15px 15px;

        .ant-typography,
        ant-typography-ellipsis {
          color: @newDarkGrey;
        }
      }

      .product-modal-secondary-data {
        // display: flex;
        // align-items: start;
        // justify-content: center;
        font-size: 0.8em;
        line-height: 1.2;
        padding-top: 5px;
        min-height: 50px;
        height: 100%;
        font-weight: 700;
        color: @newDarkGrey;
        padding-right: 10px;

        > div {
          padding-right: 5px;

          > div {
            padding-right: 5px;
            float: left;
          }
        }

        .catalog-item-card-stock {
          min-width: 80px;
          font-size: 12px;
          text-transform: uppercase;
        }

        .product-modal-price-iva {
          // display: flex;
          align-items: center;
          font-size: 11px;
        }
      }
    }

    .catalog-item-detail-info-wrapper {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;

      .catalog-item-detail-pictures {
        min-height: 225px;
        max-width: 225px;
        margin: 0 auto;

        .catalog-item-detail-picture {
          max-height: 225px;
          max-width: 225px;
          margin: 0 auto;
        }
      }

      .catalog-item-detail-info {
        display: flex;
        flex-direction: column;
        width: '100%';
        height: '100%';

        .catalog-item-detail-info-prices {
          display: inline-block;
          color: @newDarkGrey;

          @media (max-width: 575px) {
            // Media query for xs screens
            display: flex;
            flex-wrap: wrap;
          }

          .bg-deal-price {
            background-color: @redGradientLightTransparent;
            padding: 2px 15px 2px 2px;
            border-radius: 3px;
          }

          .product-modal-price-container {
            display: inline-block;
            width: 150px;
            align-items: center;
            margin: 5px auto;

            .price-label {
              text-transform: uppercase;
              font-weight: 600;
              font-size: 11px;
            }

            .price-value {
              display: block;
              font-size: 17px;
              max-width: 200px;
            }
          }

          .product-modal-price-container div {
            border-bottom: 0px solid @red;
            margin-left: 0px;
          }
        }

        .catalog-item-detail-info-actions {
          display: flex;
          justify-self: center;
          width: '100%';
          flex-direction: row;
          margin-top: 10px;
          padding-right: 10px;

          .catalog-item-detail-info-actions-right-col {
            display: flex;
            flex: 1;
            width: '50%';
            background-color: 'red';
            flex-direction: column;
            padding-left: 10px;
            .quantity {
              margin-right: 5px;
              label {
                text-align: right;
                vertical-align: center;
              }
            }
          }

          &.close-only {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 5px;
          }
        }
      }
    }

    .product-modal-form {
      display: flex;
      justify-content: space-evenly;
      padding-left: 7%;
      padding-right: 7%;

      .product-modal-form-observations {
        width: 80%;
        margin-right: 20px;

        .text-area {
          border-radius: 0.5rem;
          box-shadow:
            0 0 10px @light-grey,
            0 0 10px @light-grey;
        }
      }

      .product-modal-form-quantity {
        width: 100%;

        .inputs-and-icon {
          display: flex;
        }

        .label {
          align-content: center;
          margin-right: 5px;

          label {
            margin-bottom: 0px;
          }
        }

        .field {
          width: 100%;
          border-radius: 0.5rem;
          box-shadow:
            0 0 10px @light-grey,
            0 0 10px @light-grey;
        }
      }

      .icon-cart {
        margin-left: 10px;

        .anticon {
          color: white;
          font-size: 20px;
        }
      }

      @media (max-width: 575px) {
        // Media query for xs screens
        justify-content: center;
        flex-wrap: wrap;

        .product-modal-form-observations {
          width: 100%;
          margin-right: 0;
        }

        .product-modal-form-quantity {
          margin-top: 10px;
          width: 100%;

          .field {
            width: 100%;
          }
        }
        .icon-cart {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }

    .product-modal-divider {
      min-width: unset;
      margin-top: 15px;
      margin-bottom: 0px;
      background-color: @blue;
      width: 90% !important;
      margin-left: auto !important;
      margin-right: auto !important;
      height: 1px !important;
    }

    .product-modal-footer {
      display: flex;
      text-align: center;
      align-content: center;
      align-items: center;
      padding: 15px;
      border-radius: 0 0 10px 10px;

      .detail-container {
        display: flex;
        flex-direction: column;

        .ant-typography,
        ant-typography-ellipsis {
          color: @newDarkGrey;
        }

        .product-modal-footer-description {
          font-weight: bold;
          text-align: start;
          margin-bottom: 4px;
        }
      }
    }

    .product-modal-characteristics {
      border-radius: 0 0 10px 10px;
      padding: 10px;
    }
  }
}
