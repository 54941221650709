
@media print {
  .hidden-print {
    display: none !important;
  }
}

//Mixins
.flex-column() {
  display: flex;
  flex-direction: column;
}

.catalog-title-bar() {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: @white;
  padding-left: 10px;
  line-height: 43px;
  font-weight: bold;
  font-size: 13px;
}

.custom-drawer() {
  height: 100%;
  transition: transform 0.3s, opacity 0.3s ease;
  width: 0;
  overflow: hidden;
  transition: 0.5s;
  background-color: #fff;
  @media (min-width: 769px) {
    border-left: 1px solid @light-grey;
    border-right: 1px solid @light-grey;
  }
  transform: translateX(-100%);
  opacity: 0;
  display:block;

  >div {
    padding-top: 15px;
  }
}

/* Nav bar */
.catalog-nav-bar{
  background-color: #fff;
  justify-content: center;
  border-bottom: 2px solid @light-grey;

  .catalog-nav-bar-menu {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 480px) {
    .catalog-nav-bar-menu {
        max-width: 50%;
    }
  }

  .ant-menu-horizontal {
    line-height: 35px !important;
    border-bottom: none !important;
    margin-bottom: 1px;
  }
}

@media (min-width: 480px) {
  .catalog-nav-bar {
    padding-right: 20px;
  }
}

.fix-header-icon {
  top: 2px;
  left: 15px;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  font-size: 16px;
  transition: font-size 0.3s ease;
  cursor: pointer;
}

.fix-header-icon:hover {
  font-size: 20px; 
}

.fix-header-icon:hover::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border: 2px solid @red;
  border-radius: 50%;
  transition: width 0.3s ease, height 0.3s ease, border-color 0.3s ease;
  pointer-events: none;
}

/* search bar */
.catalog-search-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid @light-grey;
  width: 100%;

  i {
    color: @red;
  }

  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }

  .catalog-search-bar-main {
    display: flex;
    padding: 0 10px;
  }

  .catalog-search-bar-search {
    margin: 0 10px;

      .ant-form-item{
        margin-bottom: 12px;
    }
  }

  .actions {
    display: inline-block;
    font-size: 22px !important;
    text-align: center;
    cursor: pointer;
    padding: 0 3px;
  }
}

/* items cards */
.catalog-items-cards {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;

  .catalog-item-card-wrapper {
    margin: 10px 15px;
    float: left;
    @media (min-width: 769px) {
      width: 320px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }

  }

  .catalog-item-card-stock>div {
    display: inline-block;
  }

  .deal-item {
    width: 100%;
    height: 300px;
    padding: 3px;
  }

  .catalog-item-card {
    .flex-column();
    width: 100%;
    background-color: @white;
    border-radius: 1rem;
    box-shadow: 0 0 20px @dark-grey, 0 0 10px @dark-grey;

    .catalog-item-card-title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      .catalog-title-bar()
    }

    .deal-item-card-picture-prices {
      display: flex;
      background-color: #fff;
      margin: 10px;
    }
    .catalog-item-card-picture-prices {
      display: flex;
      height: 150px;
      background-color: @white;
      justify-content: center;
      position: relative;
      overflow: hidden;

      .catalog-item-card-prices {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding-left: 5px;

        @media (max-width: 480px) {
          width: 60%;

          .catalog-item-secondary-data{
            justify-content: flex-end;
          }
        }
      }

      .catalog-item-card-picture {
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit:contain;
        }

        .textLabelCardContent {
          position: absolute;
          top: 45%;
          left: 19%;
          background-color: orange;
          color: white;
          padding: 5px 0;
          font-size: 13px;
          font-weight: bold;
          text-align: center;
          transform: translate(-50%, -50%) rotate(-45deg);
          transform-origin: bottom left;
          white-space: nowrap;
          width: 120px;
        }
      }

      .catalog-item-card-picture:hover { cursor: pointer; }
    }

    .catalog-item-card-bottom {

      .item-card-bottom-divider {
        min-width: unset;
        margin-top: 5px;
        margin-bottom: 0px;
        background-color: @dark-grey;
        width: 90%!important;
        margin-left: auto!important;
        margin-right: auto!important;
        height: 2px!important;
      }

      .catalog-item-card-price {
        color: @newDarkGrey;
        font-weight: 500;
        font-size: 10pt;
        text-decoration: none;
        padding-bottom: 5px;
        line-height: normal;
        margin-top: 10px;
      }

      .deal-price {
        background-color: @redGradientLightTransparent;
        border-radius: 3px;
        padding: 2px;
      }

      .catalog-item-card-price div span {
        display: block;
        font-size: 1.1em;
        font-weight: 700;
      }

      .catalog-item-card-iva {
        display: flex;
        color: @newDarkGrey;
        justify-content: center;
        font-size: 7pt;
        align-items: flex-end;
      }
    }

    .catalog-item-card-detail {
      padding: 0 15px;
      white-space: normal;
      overflow: hidden;
      color: @newDarkGrey;
      font-weight: 500;
      font-size: 10pt;
      text-decoration: none;
    }

    .catalog-item-card-actions {

      a {
        line-height: 40px;
        padding-left: 10px;
      }

      .catalog-item-card-actions-buttons{

        .icon-card-item{
          margin-top: 1px;
          font-size: 20px;
          color: @white;
        }

        margin-left: auto;
        padding-right: 5px;
        padding-top: 5px;

        i {
          font-size: 1.5em;
        }

        button {
          margin-left: 10px;
        }

        // button:last-of-type {
        //   i {
        //     color: #fff;
        //   }
        // }
      }
    }
  }
}


.ant-table-pagination.ant-pagination {
  margin: 20px;
  float: right ;
}

/* pagination */
.catalog-items-pagination {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-no-result{
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.catalog-items {
  transition: all .5s, margin-right .5s;
}

.catalog-item-secondary-data {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8em;
  line-height: 1;
  padding-bottom: 5px;
  min-height: 50px;

  >div{
    padding-right: 5px;

    >div {
        padding-right: 5px;
        float: left;
      }
  }
}

.catalog-main-panel {
  width: 100%;
  display: flex;
  //height: 745px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover .ant-collapse-expand-icon {
  .anticon {
    color: @red;
  }
}

.categories-header-list > .ant-collapse-header:hover{
    color: @red !important;
    font-weight: bold;
    .anticon{
      color: @dark-grey;
    }
}


.ant-collapse-item-active.categories-header-list > .ant-collapse-header {
  color: @red !important;
  font-weight: bold;
  .anticon{
    color: @dark-grey;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  top:55%;
}

.brands-header-list > .ant-collapse-header:hover{
      &:extend(.red-gradient);
      padding: 3px;
      border-radius: 3px;
      font-weight: bold;
      transition: background ease-in .2s, padding ease-in .2s, border-radius ease-in .2s, font-weight ease-in .2s;

    &:hover {
        color: white;
    }
}

.ant-collapse-item-active.brands-header-list > .ant-collapse-header {

  &:extend(.red-gradient);
  color: white;
  font-weight: bold;
}

.brands-leaf {
  margin-left: 28px;
}

/* drawers */
.categories-drawer {
  left: 0;
  .custom-drawer();

  .anticon {
    color: @dark-grey;
  }

  &.open {
    transform: translateX(0%);
    opacity: 1;
    display:block;
    @media (min-width: 769px) {
      width: 300px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    height: 100%;
  }

  .ant-list-split .ant-list-item {
    border: none;
  }

  .ant-list-item {
    padding-top: 0px;
    padding-bottom: 0px;

    &:hover {
      background: @redGradientLight;
      padding: 3px;
      border-radius: 3px;
      font-weight: bold;
      transition: background ease-in .2s, padding ease-in .2s, border-radius ease-in .2s, font-weight ease-in .2s;
    }

    &:hover {
        color: white;
    }
  }

  .item-selected {
    background: @redGradientLight;
    color: white; 
    padding: 3px;
    border-radius: 3px;
    font-weight: bold;
    transition: background ease-in .2s, padding ease-in .2s, border-radius ease-in .2s, font-weight ease-in .2s;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-bottom: 4px;

    .ant-list {
      padding-left: 40px;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 4px;
    padding-bottom: 4px;
  }

}

.categories-drawer-open {
  margin-left: 150px;
}

.cart-drawer {
  .custom-drawer();
  right: 0;
  transition: 0s !important;

  &.open {
    transform: translateX(0%);
    opacity: 1;
    display:block;
    width: 100%;
  }
}

.quick-cart {
  .ant-drawer-content-wrapper {
    width: 550px !important;
  }

  .ant-input-number {
    width: 100%;
  }

  .name-input {
    width: 80%;
    margin: auto;

    .ant-row {
      margin-bottom: 0px;
    }
  }

  .totals {
    align-items: flex-end;
    margin-right: 10px;

    .total-amount {
      display: flex;
    }

    .flex-column();
    margin-bottom: 10px;

    div {
      text-align: right;
    }
  }

  .info {
    .flex-column();
    margin-bottom: 10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .actions {
    width: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;

    i {
      color: @red;
      font-size: 1.5em;
      cursor: pointer;
    }
  }

  tr {
    >td {
      padding: 8px 10px !important;
    }
  }
}

.catalog-item-stock-status {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.catalog-item-stock-status.red{
  background-color: @red-6;
}
.catalog-item-stock-status.yellow{
  background-color: @yellow-6;
}
.catalog-item-stock-status.green{
  background-color: @green-6;
}
.catalog-item-stock-status.grey{
  background-color: @light-grey;
}


.ant-carousel .slick-dots li button, .ant-carousel .slick-dots li.slick-active button {
  background: @red;
}

/* table */
.catalog-items-table {

  .ant-table-thead > tr > th {
    padding: 16px 5px;
  }

  tr {
    >td {
      padding: 5px !important;
    }
  }

  .catalog-items-table-actions {
    font-size: 20px;
  }

  .itemListImage {
    >img {
      width: 100%;
    }
  }
}

.catalog-items-default{
  //border: solid 1px red;
  min-height: 600px;
  height: 100%;
  margin-top: 15px;
  .item-default-content{
    padding: 10px 10px;
    background-image: linear-gradient(to right, #D5D4D4 , #FEFEFF);
    height: 100%;

    .title-content{
      color:#fff;
      font-size: 36pt;
      font-style: italic;
    }
    .item-default-logo{
      text-align: right;
      padding-right: 10%;
      padding-bottom: 30px;
    }
  }
}

.actions {
  i {
    cursor: pointer;
  }
}

.pricesList-modal-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pricesList-modal-button-group {
  button + button {
    margin-left: 10px;
  }
}

.spin-container {
  width: 100%;
  text-align: center;
}

.sale-price {
  background-color: @color-2;
}

.welcome-logo {
  width: 50%;
}

// MOBILE
//@media (max-width: 991.98px) {
//  .drawer-open {
//    display: none;
//  }
//}

.statistics-div-table{
  .ant-table {
    overflow-x: auto;
  }
}

.statistics-table-cell{
  min-width: 120px;
}

.statistics-table-cell.subline {
  min-width: 300px;
}

.order-products-table tr > td {
  padding: 4px 16px;
}

.quotation-products-table tr > td {
  padding: 4px 16px;
}

.name-input label {
  margin-bottom: 0;
}

.name-input .ant-form-item-label {
  padding-bottom: 4px;
}

@media (max-width: 768px) {
  .carousel_fix_with {
    margin: 0 auto;
    width: 300px;
  }
}

.ant-carousel .slick-dots-bottom {
  bottom: 0px;
}

.ant-back-top-content {
  background-color: @color-5;

  .anticon {
    color: white;
  }
}

.ant-back-top-content:hover {
  background-color: @color-7;
}


.whatsapp-button-custom {
  height: 40px;
  margin-top: 3px;
  padding: 0;
  border: none;
  background: none !important;
  z-index: 1000;
  box-shadow: none !important;
}

.whatsapp-button-custom:hover, .whatsapp-button-custom:focus, .whatsapp-button-custom:active {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  transform: none !important;
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
  display: block;
}